import type { GuardOptions, GuardState } from '../core';
import { createGuard } from '../core';

type GuardApi = {
  state: GuardState<string>;
  guardEmpty: (_options?: GuardOptions) => Readonly<GuardApi>;
  guardMaxLength: (_maxLength: number, _options?: GuardOptions) => Readonly<GuardApi>;
  guardMinLength: (_minLength: number, _options?: GuardOptions) => Readonly<GuardApi>;
  guardByRegexp: (_regex: RegExp, _options?: GuardOptions) => Readonly<GuardApi>;
  guardXSS: (_options?: GuardOptions) => Readonly<GuardApi>;
};

export const guardEmptyString = (val: string) => val !== '';

export const guardMaxLengthString = (val: string, maxLength: number) =>
  val.length <= maxLength;

export const guardMinLengthString = (val: string, minLength: number) =>
  val.length >= minLength;

export const guardByRegexpString = (val: string, regex: RegExp) => regex.test(val);

export const guardXssString = (val: string) => {
  const regex = /<(|\/|[^/>][^>]+|\/[^>][^>]+)>|(">)/i;

  return !guardByRegexpString(val, regex);
};

// TODO: сделать более удобный фактори для api гуард, чтобы было проще их создавать.
export const stringsGuard = (val: string): Readonly<GuardApi> => {
  const state: GuardState<string> = {
    data: val,
    isValid: true,
    messages: [],
    checkList: {}
  };

  const api: GuardApi = {
    state,
    guardEmpty: (options?: GuardOptions) =>
      createGuard(api, 'guardEmpty', guardEmptyString(val), options),
    guardMaxLength: (maxLength: number, options?: GuardOptions) =>
      createGuard(
        api,
        'guardMaxLength',
        guardMaxLengthString(state.data, maxLength),
        options
      ),
    guardMinLength: (minLength: number, options?: GuardOptions) =>
      createGuard(
        api,
        'guardMinLength',
        guardMinLengthString(state.data, minLength),
        options
      ),
    guardByRegexp: (regex: RegExp, options?: GuardOptions) =>
      createGuard(api, 'guardByRegexp', guardByRegexpString(state.data, regex), options),
    guardXSS: (options?: GuardOptions) =>
      createGuard(api, 'guardXSS', guardXssString(state.data), options)
  };

  return Object.freeze(api);
};
