export const query = (query: string, ctx: Element | Document = document) =>
  ctx.querySelector(query);

export const queryAll = (query: string, ctx: Element | Document = document) =>
  ctx.querySelectorAll(query);

export const queryByClassName = (query: string, ctx: Element | Document = document) =>
  ctx.getElementsByClassName(query);

export const queryByTagName = (query: string, ctx: Element | Document = document) =>
  ctx.getElementsByTagName(query);

export const queryById = (query: string) => document.getElementById(query);
