import { preventDefault } from '../events';
import { query } from '../query';

import { smoothScroll } from './smooth-scroll';

export const getScrollBarWidth = () => window.innerWidth - document.body.clientWidth;

export const getScrollTop = () => {
  const { scrollingElement } = document;

  if (scrollingElement) {
    return scrollingElement.scrollTop;
  }

  const html = <HTMLElement>query('html');
  const body = <HTMLElement>query('body');

  return html.scrollTop ?? body.scrollTop;
};

export type ScrollParams = {
  top?: number;
  left?: number;
  behavior?: 'auto' | 'smooth';
  useFallback?: boolean;
  duration?: number;
};

export const scrollTo = (params: ScrollParams) => {
  const {
    top = 0,
    left,
    behavior = 'auto',
    useFallback = false,
    duration = 300
  } = params;

  if (useFallback) {
    smoothScroll(top, duration);
  } else {
    window.scrollTo({
      top,
      left,
      behavior
    });
  }
};

export type TouchDirectionOptions = {
  event: TouchEvent;
  clientX: number;
  clientY: number;
};

export type Direction = {
  down: boolean;
  up: boolean;
  left: boolean;
  right: boolean;
  vertical: boolean;
};

export const getTouchDirection = (options: TouchDirectionOptions): Direction => {
  const { event, clientX, clientY } = options;

  const deltaX = event.targetTouches[0].clientX - clientX;
  const deltaY = event.targetTouches[0].clientY - clientY;

  return {
    down: deltaY > 0,
    up: deltaY < 0,
    left: deltaX < 0,
    right: deltaX > 0,
    vertical: Math.abs(deltaY) > Math.abs(deltaX)
  };
};

export const getElementScrollState = (target: HTMLElement, direction: Direction) => {
  const { scrollTop, scrollLeft, scrollWidth, scrollHeight, clientWidth, clientHeight } =
    target;

  const { down, left, right, up } = direction;

  return {
    isOnTop: down && scrollTop === 0,
    isOnLeft: right && scrollLeft === 0,
    isOnRight: left && scrollLeft + clientWidth + 1 >= scrollWidth,
    isOnBottom: up && scrollTop + clientHeight + 1 >= scrollHeight
  };
};

export type HandleScrollOptions = TouchDirectionOptions & {
  target: HTMLElement;
};

export const preventBodyScroll = (options: HandleScrollOptions) => {
  const { event, target } = options;

  if (target !== undefined) {
    const direction = getTouchDirection(options);
    const { vertical } = direction;

    const { isOnBottom, isOnLeft, isOnRight, isOnTop } = getElementScrollState(
      target,
      direction
    );

    if ((vertical && (isOnTop || isOnBottom)) || (!vertical && (isOnLeft || isOnRight))) {
      return preventDefault(event);
    }
  }

  event.stopPropagation();
  return true;
};
