import type { GuardOptions, GuardState } from '../core';
import { createGuard } from '../core';

type GuardApi = {
  state: GuardState<number>;
  guardZero: (_options?: GuardOptions) => Readonly<GuardApi>;
  guardMax: (_max: number, _options?: GuardOptions) => Readonly<GuardApi>;
  guardMin: (_min: number, _options?: GuardOptions) => Readonly<GuardApi>;
  guardNaN: (_options?: GuardOptions) => Readonly<GuardApi>;
  guardNegative: (_options?: GuardOptions) => Readonly<GuardApi>;
  guardOutOfRange: (
    _min: number,
    _max: number,
    _options?: GuardOptions
  ) => Readonly<GuardApi>;
};

export const guardZeroNumber = (val: number) => val !== 0;

export const guardMaxNumber = (val: number, max: number) => val <= max;

export const guardMinNumber = (val: number, min: number) => val >= min;

export const guardNaNNumber = (val: number) => !Number.isNaN(val);

export const guardNegativeNumber = (val: number) => val >= 0;

export const guardOutOfRangeNumber = (val: number, range: number[]) => {
  const [min, max] = range.sort((a, b) => a - b);
  return val >= min && val <= max;
};

export const numberGuard = (val: number): Readonly<GuardApi> => {
  const state: GuardState<number> = {
    data: val,
    isValid: true,
    messages: [],
    checkList: {}
  };

  const api: GuardApi = {
    state,
    guardZero: (options?: GuardOptions) =>
      createGuard(api, 'guardZero', guardZeroNumber(val), options),
    guardMax: (max: number, options?: GuardOptions) =>
      createGuard(api, 'guardMax', guardMaxNumber(state.data, max), options),
    guardMin: (min: number, options?: GuardOptions) =>
      createGuard(api, 'guardMin', guardMinNumber(state.data, min), options),
    guardNaN: (options?: GuardOptions) =>
      createGuard(api, 'guardNaN', guardNaNNumber(state.data), options),
    guardNegative: (options?: GuardOptions) =>
      createGuard(api, 'guardNegative', guardNegativeNumber(state.data), options),
    guardOutOfRange: (min: number, max: number, options?: GuardOptions) =>
      createGuard(
        api,
        'guardOutOfRange',
        guardOutOfRangeNumber(state.data, [min, max]),
        options
      )
  };

  return Object.freeze(api);
};
