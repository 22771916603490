export type Selector = HTMLElement | HTMLCollection | NodeList | Element;

export const isNodeList = (selector: Selector): selector is NodeList => {
  return NodeList.prototype.isPrototypeOf.call(NodeList.prototype, selector);
};

export const isHTMLCollection = (selector: Selector): selector is HTMLCollection => {
  return Object.prototype.isPrototypeOf.call(HTMLCollection.prototype, selector);
};

export const isHTMLElement = (selector: Selector): selector is HTMLElement => {
  return Object.prototype.isPrototypeOf.call(HTMLElement.prototype, selector);
};

export const isElement = (selector: Selector): selector is Element => {
  return Object.prototype.isPrototypeOf.call(Element.prototype, selector);
};

export const isChildOfSelector = (selector: Selector, targetNode: Node) => {
  if (isNodeList(selector) || isHTMLCollection(selector)) {
    if (Array.from(selector).some(s => s.contains(targetNode))) {
      return true;
    }
  } else if (isHTMLElement(selector) || isElement(selector)) {
    if (selector.contains(targetNode)) {
      return true;
    }
  } else {
    throw new TypeError('Unexpected type of selector');
  }

  return false;
};
