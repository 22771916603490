import {
  setBodyOverflowHiddenPc,
  setBodyOverflowHiddenMobile,
  setBodyOverflowHiddenIos,
  preventBodyScroll
} from '@portal/utils/util-dom';
import { Nullable } from '@portal/utils/util-type-fest';
import { parse } from '@portal/utils/util-ua';

let lockedNum = 0;
let initialClientY = 0;
let initialClientX = 0;
let unLockCallback: (() => void) | null = null;

const lockedElements: HTMLElement[] = [];

const detectOS = () => parse(navigator.userAgent);

/**
 * If scrolling is also required in the floating layer, the target element must be provided.
 * @param targetElement
 */
const lock = (targetElement: Nullable<HTMLElement> = null) => {
  if (detectOS().isiOS) {
    unLockCallback = setBodyOverflowHiddenIos();
    if (targetElement !== null && lockedElements.indexOf(targetElement) === -1) {
      targetElement.ontouchstart = event => {
        initialClientY = event.targetTouches[0].clientY;
        initialClientX = event.targetTouches[0].clientX;
      };

      targetElement.ontouchmove = event => {
        if (event.targetTouches.length !== 1) {
          return;
        }

        preventBodyScroll({
          event,
          target: targetElement,
          clientX: initialClientX,
          clientY: initialClientY
        });
      };

      lockedElements.push(targetElement);
    }
  } else if (lockedNum <= 0) {
    unLockCallback = detectOS().isAndroid
      ? setBodyOverflowHiddenMobile()
      : setBodyOverflowHiddenPc();
  }

  lockedNum += 1;
};

/**
 * If scrolling is also required in the floating layer, the target element must be provided.
 * @param targetElement
 */
const unlock = (targetElement: Nullable<HTMLElement> = null) => {
  lockedNum -= 1;

  if (lockedNum > 0) {
    return;
  }

  if (detectOS().isiOS) {
    if (targetElement !== null) {
      const index = lockedElements.indexOf(targetElement);

      if (index !== -1) {
        targetElement.ontouchmove = null;
        targetElement.ontouchstart = null;
        lockedElements.splice(index, 1);
      }
    }
  }

  if (typeof unLockCallback === 'function') {
    unLockCallback();
    return;
  }
};

export { lock, unlock };
