export const isJsDom = (): boolean =>
  typeof navigator === 'object' &&
  navigator.userAgent.length > 0 &&
  navigator.userAgent.includes('jsdom');

export const isNode = (): boolean =>
  typeof process === 'object' &&
  process.versions !== undefined &&
  process.versions.node !== undefined;

export const isBrowser = (): boolean =>
  typeof window === 'object' && typeof document === 'object' && document.nodeType === 9;
