import { Selector, isChildOfSelector } from '@portal/utils/util-dom';

type Callback = (event: Event) => void;
type Options = {
  isKeepAlive?: boolean;
  isCapture?: boolean;
  isSkipFirstClick?: boolean;
};
type Listener = {
  selector: Selector;
  callback: Callback;
  options: Options;
  isFirstClick: boolean;
};
type IsResolved = boolean;

let isClickOutsideEventListenerSet = false;

const DEFAULT_OPTIONS: Options = {
  isKeepAlive: false,
  isCapture: false,
  isSkipFirstClick: true
};

const listeners: Set<Listener> = new Set();

const resolver = (
  event: MouseEvent,
  selector: Selector,
  callback: Callback
): IsResolved => {
  if (isChildOfSelector(selector, event.target as Node)) {
    return false;
  }

  callback(event);
  return true;
};

const handler = (event: MouseEvent) => {
  listeners.forEach(listener => {
    if (listener.isFirstClick) {
      listener.isFirstClick = false;
      return;
    }

    const { selector, callback } = listener;
    const isResolved = resolver(event, selector, callback);

    if (isResolved && !listener.options.isKeepAlive) {
      listeners.delete(listener);
    }
  });

  if (listeners.size === 0) {
    document.removeEventListener('click', handler);
    isClickOutsideEventListenerSet = false;
  }
};

const clickOutside = (
  selector: Selector,
  callback: Callback,
  options: Options = DEFAULT_OPTIONS
) => {
  if (!isClickOutsideEventListenerSet) {
    document.addEventListener('click', handler, options.isCapture);
    isClickOutsideEventListenerSet = true;
  }

  listeners.add({
    selector,
    callback,
    options,
    isFirstClick: options.isSkipFirstClick ?? true
  });
};

export { clickOutside, resolver, handler, listeners };
