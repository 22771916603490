import { isBrowser } from '@portal/utils/util-is-in-browser';

const TEST_EVENT = '__BSL_TEST_PASSIVE__';

export const getPassiveEventListenerOptions = (
  capture?: boolean
): AddEventListenerOptions | boolean =>
  isPassiveEventListenerSupported()
    ? {
        passive: false
      }
    : capture !== undefined
    ? capture
    : false;

export const isPassiveEventListenerSupported = (): boolean => {
  if (!isBrowser()) {
    return false;
  }

  let isSupported = false;
  const listenerOptions = <AddEventListenerOptions>{
    get passive() {
      isSupported = true;
      return false;
    }
  };

  const noop = () => {
    /* do nothing.*/
  };

  window.addEventListener(TEST_EVENT, noop, listenerOptions);
  window.removeEventListener(TEST_EVENT, noop, listenerOptions);

  return isSupported;
};

export const preventDefault = (event: Event) => {
  if (!event.cancelable) {
    return;
  }

  event.preventDefault();
};

export const createCustomEvent = <D = unknown>(
  name: string,
  detail?: D,
  options?: EventInit
) => {
  try {
    return new CustomEvent<D>(name, {
      detail,
      ...options
    });
  } catch {
    const event = document.createEvent('CustomEvent') as CustomEvent<D>;
    event.initEvent(name, options?.bubbles, options?.cancelable);
    return event;
  }
};
