export const smoothScroll = (destination: number, duration: number) => {
  const start = window.pageYOffset;
  const startTime =
    'now' in window.performance ? performance.now() : new Date().getTime();
  const endTime = startTime + duration;

  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight
  );
  const windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.getElementsByTagName('body')[0].clientHeight;
  const destinationOffsetToScroll = Math.round(
    documentHeight - destination < windowHeight
      ? documentHeight - windowHeight
      : destination
  );

  if ('requestAnimationFrame' in window === false) {
    window.scroll(0, destinationOffsetToScroll);
    return;
  }

  scroll();

  function scroll() {
    const now = 'now' in window.performance ? performance.now() : new Date().getTime();
    const time = Math.min(1, (now - startTime) / duration);
    const pageYOffset = Math.round(window.pageYOffset);

    window.scroll(0, Math.ceil(time * (destinationOffsetToScroll - start) + start));

    if (pageYOffset === destinationOffsetToScroll || now > endTime) {
      return;
    }

    requestAnimationFrame(scroll);
  }
};
