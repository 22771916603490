import type { GuardOptions, GuardState } from '../core';
import { createGuard } from '../core';

type SomeArray = unknown[];

type GuardApi = {
  state: GuardState<SomeArray>;
  guardEmpty: (_options?: GuardOptions) => Readonly<GuardApi>;
  guardMaxLength: (_maxLength: number, _options?: GuardOptions) => Readonly<GuardApi>;
  guardMinLength: (_minLength: number, _options?: GuardOptions) => Readonly<GuardApi>;
};

export const guardEmptyArray = (val: SomeArray) => val.length > 0;

export const guardMaxLengthArray = (val: SomeArray, maxLength: number) =>
  val.length <= maxLength;

export const guardMinLengthArray = (val: SomeArray, minLength: number) =>
  val.length >= minLength;

export const arrayGuard = (val: SomeArray): Readonly<GuardApi> => {
  const state: GuardState<SomeArray> = {
    data: val,
    isValid: true,
    messages: [],
    checkList: {}
  };

  const api: GuardApi = {
    state,
    guardEmpty: (options?: GuardOptions) =>
      createGuard(api, 'guardEmpty', guardEmptyArray(val), options),
    guardMaxLength: (maxLength: number, options?: GuardOptions) =>
      createGuard(
        api,
        'guardMaxLength',
        guardMaxLengthArray(state.data, maxLength),
        options
      ),
    guardMinLength: (minLength: number, options?: GuardOptions) =>
      createGuard(
        api,
        'guardMinLength',
        guardMinLengthArray(state.data, minLength),
        options
      )
  };

  return Object.freeze(api);
};
