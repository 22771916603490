const OS = {
  iPad: /(iPad).*OS\s([\d_]+)/,
  iPhone: /(iPhone\sOS)\s([\d_]+)/
};

const PLATFORM = {
  android: /(Android);?[\s/]+([\d.]+)?/
};

export type UAInfo = {
  isiOS: boolean;
  isAndroid: boolean;
  isiPad: boolean;
  isiPhone: boolean;
};

export const parse = (ua: string): UAInfo => {
  const isiPad = OS.iPad.test(ua);
  const isiPhone = !isiPad && OS.iPhone.test(ua);
  const isAndroid = PLATFORM.android.test(ua);
  const isiOS = isiPad || isiPhone;

  return {
    isiOS,
    isAndroid,
    isiPad,
    isiPhone
  };
};
