import { query } from '../query';
import { getScrollBarWidth, getScrollTop, scrollTo } from '../scroll';

type CssProp = Exclude<
  keyof CSSStyleDeclaration,
  | 'length'
  | 'parentRule'
  | 'getPropertyPriority'
  | 'getPropertyValue'
  | 'item'
  | 'removeProperty'
  | 'setProperty'
  | 'getModuleIds'
  | '[index: number]'
  | number
>;

const TIMEOUT_FOR_IOS_BOTTOM_BAR_ANIMATION = 300;

export const pickStyle = (obj: CSSStyleDeclaration, keys: CssProp[]) => {
  const res: Partial<Record<CssProp, string>> = {};

  if (Object.keys(obj).length === 0) {
    return res;
  }

  return keys.reduce((acc, current) => {
    acc[current] = obj[current] as string;
    return acc;
  }, res);
};

export const setStyle = (el: HTMLElement, prop: CssProp, value: string) => {
  (el.style[prop] as string) = value;
};

export const setBodyOverflowHiddenPc = () => {
  const CSS_PROPS: CssProp[] = ['overflow', 'boxSizing', 'paddingRight'];

  const body = <HTMLElement>query('body');
  const bodyStyle = pickStyle(body.style, CSS_PROPS);
  const scrollBarWidth = getScrollBarWidth();

  body.style.overflow = 'hidden';
  body.style.boxSizing = 'border-box';
  body.style.paddingRight = `${scrollBarWidth}px`;

  return () => {
    CSS_PROPS.forEach((x: CssProp) => {
      (body.style[x] as string) = bodyStyle[x] ?? '';
    });
  };
};

export const setBodyOverflowHiddenMobile = () => {
  const HTML_CSS_PROPS: CssProp[] = ['height'];
  const BODY_CSS_PROPS: CssProp[] = ['top', 'width', 'height', 'overflow', 'position'];
  const html = <HTMLElement>query('html');
  const body = <HTMLElement>query('body');

  const scrollTop = getScrollTop();

  const htmlStyle = pickStyle(html.style, HTML_CSS_PROPS);
  const bodyStyle = pickStyle(body.style, BODY_CSS_PROPS);

  html.style.height = 'auto';

  body.style.top = `-${scrollTop}px`;
  body.style.width = '100%';
  body.style.height = 'auto';
  body.style.position = 'fixed';
  body.style.overflow = 'hidden';

  return () => {
    html.style.height = htmlStyle.height ?? '';
    html.style.overflow = htmlStyle.overflow ?? '';

    BODY_CSS_PROPS.forEach((x: CssProp) => {
      (body.style[x] as string) = bodyStyle[x] ?? '';
    });

    scrollTo({
      top: scrollTop,
      left: 0
    });
  };
};

export const setBodyOverflowHiddenIos = () => {
  const BODY_CSS_PROPS: CssProp[] = ['position', 'top', 'left', 'width', 'overflow'];
  const body = <HTMLElement>query('body');
  const bodyStyle = pickStyle(body.style, BODY_CSS_PROPS);
  const { scrollY, scrollX } = window;
  const scrollTop = getScrollTop();

  window.requestAnimationFrame(() => {
    const checkIosBottomBar = () => {
      setTimeout(
        () =>
          window.requestAnimationFrame(() => {
            const bottomBarHeight = innerHeight - window.innerHeight;
            if (bottomBarHeight && scrollY >= innerHeight) {
              const top = -(scrollY + bottomBarHeight);
              body.style.top = `${top}px`;
            }
          }),
        TIMEOUT_FOR_IOS_BOTTOM_BAR_ANIMATION
      );
    };

    body.style.position = 'fixed';
    body.style.top = `-${scrollY}px`;
    body.style.left = `-${scrollX}px`;
    body.style.width = '100vw';
    body.style.overflow = 'hidden';

    checkIosBottomBar();
  });

  return () => {
    BODY_CSS_PROPS.forEach((x: CssProp) => {
      (body.style[x] as string) = bodyStyle[x] ?? '';
    });

    scrollTo({
      top: scrollTop,
      left: 0
    });
  };
};

export const getElementMaxHeightByViewportBottom = (element: HTMLElement): number => {
  const elementTop = element.getBoundingClientRect().top;
  return document.documentElement.clientHeight - elementTop;
};
