import { guardUnspecified, guardEmptyString } from '@portal/utils/util-guards';

export const URL_REGEX = /^(http(s)?|ftp):\/\//;

export const isAbsoluteUrl = (url: string) => guardUnspecified(URL_REGEX.exec(url));

export const isSubdomainOfDomain = (url: string, domain: string) => {
  if (!isAbsoluteUrl(url)) {
    return false;
  }

  let result = false;

  domain = `https://${domain}/`;

  const url1 = new URL(domain).hostname.replace('www.', '').split('.').reverse();
  const url2 = new URL(url).hostname.replace('www.', '').split('.').reverse();

  if (url2 <= url1) {
    return result;
  }

  url1.forEach((item, index) => (result = item === url2[index]));

  return result;
};

export const getLinkTarget = (url: string): '_blank' | '_self' =>
  isAbsoluteUrl(url) ? '_blank' : '_self';

export const toQueryString = (
  params: Record<string, string>,
  prefix: '' | '?' | '&' = '?'
) => {
  const pairs = Object.entries(params).filter(pair => guardUnspecified(pair[1]));
  const queryString = new URLSearchParams(pairs).toString();
  return `${prefix}${queryString}`;
};

export const addQueryString = (url: string, params: Record<string, string>) => {
  const urlObj = new URL(url, 'http://0.0.0.0');
  const queryStringPrefix = guardEmptyString(urlObj.search) ? '&' : '?';
  return `${url}${toQueryString(params, queryStringPrefix)}`;
};
